(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("backbone"), require("jquery"), require("underscore"));
	else if(typeof define === 'function' && define.amd)
		define("@atlassian/aui", ["backbone", "jquery", "underscore"], factory);
	else if(typeof exports === 'object')
		exports["@atlassian/aui"] = factory(require("backbone"), require("jquery"), require("underscore"));
	else
		root["AJS"] = factory(root["Backbone"], root["jQuery"], root["_"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__) {
return 